@tailwind base;
@tailwind components;
@tailwind utilities;

/* site-wide */
@layer base {
  @media only screen {
    html {
      @apply text-base md:text-lg;

      font-optical-sizing: auto;
    }
  }
}

.bg-android {
  background-color: #5ab539;
}

@layer components {
  @media only screen {
    #app-header {
      @apply text-white;

      background-color: "#1D1F23";

      select {
        @apply text-base-content;
      }

      .menu {
        a {
          @apply text-white text-xl font-extrabold;

          &.active,
          .tab-active {
            @apply bg-neutral-700;
          }
        }
      }
    }

    .icon {
      width: 100%;
      height: auto;
    }

    .heading,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply font-extrabold;
    }

    p,
    .select {
      @apply text-base md:text-lg;
    }

    .table {
      @apply text-base md:text-lg mt-4 mb-2;
    }

    .table th {
      @apply font-extrabold text-base md:text-lg text-base-content;
    }

    .btn-link {
      @apply font-normal;
    }

    .input-bordered,
    .select-bordered,
    .radio {
      @apply border-base-content border-2;
    }

    .form-control {
      @apply my-4;
    }

    legend {
      @apply font-extrabold;
    }
  }

  /* print specific styling */
  @media only screen {
    .printable {
      @apply font-serif;

      min-width: 9in;
    }

    article,
    .printable {
      @apply prose prose-xl bg-white text-gray-900 py-16 px-20 max-w-screen-md font-bold leading-7 mb-8;
    }
  }

  @media only print {
    @page {
      size: auto;
      margin: 0;
    }

    html,
    :root {
      @apply text-black;

      font-family: initial;
      font-size: 16px;
      font-weight: 400;
    }

    body {
      @apply p-0;
    }

    article,
    .printable {
      @apply break-after-page max-w-full max-h-full prose text-black;

      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      padding-left: 0.8in;
      padding-right: 0.8in;
      padding-top: 0.5in;
    }
  }
}

/* tech debt cuz i dont know how */

.home-screen {
  .btn-primary {
    @apply bg-blue-600 text-white border-blue-600;
  }

  .btn {
    @apply text-white text-xl font-extrabold;
  }

  .btn-lg {
    @apply px-12;
  }
}
